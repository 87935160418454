<template>
  <v-container class="fill-height justify-center" tag="section">
    <v-row 
      style="
        max-width: 450px;
        height: fit-content;
      ">
      <v-col cols="12" md="12">
        <v-slide-y-transition appear>
          <v-card class="pa-3 pa-md-5 mx-auto" :dark="$vuetify.theme.dark">
            <!-- Logo row-->
            <v-row>
              <v-img
                class="mx-auto mt-4 mb-8"
                max-width="100%"
                :src="'/assets/branding/' + (($vuetify.theme.dark) ? 'poweredByDoohDark.png' : 'poweredByDoohLight.png')" 
              ></v-img>
            </v-row>

            <v-row>
              <v-col cols="12" md="12">
                <v-row class="text-center">
                  <!-- Enter password -->
                  <v-text-field
                    color="primary"
                    label="Password*"
                    append-icon="mdi-lock"
                    type="password"
                    v-model="password1"
                    :error='resetError'
                    class="px-2"
                  />

                  <!-- Confirm password -->
                  <v-text-field
                    color="primary"
                    label="Confirm Password*"
                    append-icon="mdi-lock"
                    type="password"
                    v-model="password2"
                    :error='resetError'
                    onpaste="return false"
                    class="px-2"
                    style="width: 100%;"
                  />
                  <!-- Privacy Policy Agreement -->
                  <v-row class="px-4" style="width: 100%;">
                    <v-checkbox v-model="privacyPolicy" :input-value="false" color="primary">
                      <template v-slot:label>
                        <span class="text-no-wrap">I agree to the processing and storage of my personal data <br /> in accordance with the
                          <a
                            class="primary--text ml-6 ml-sm-0" 
                            @click="goToPrivacyPolicy"
                          >
                            Privacy Policy 
                          </a>. This may include <br /> recieving updates and alerts tailored to my preferences. <br />
                          I understand that i can widthdraw my consent at any time.
                        </span>
                      </template>
                    </v-checkbox>
                  </v-row>

                  <!-- Message from response -->
                  <v-row v-if="resetOutcome !== null" style="width: 100%;">
                    <v-col cols="12" class="text-center">
                      <span
                        v-bind:style="[
                          resetError === true
                            ? { color: '#ff5252' }
                            : { color: '#04B526' },
                        ]"
                        >{{ resetOutcome }}</span
                      >
                    </v-col>
                  </v-row>

                  <!-- Password progress bar -->
                  <v-row 
                    v-if="this.password1 && this.password1.length > 0"
                    justify="center"
                    class="px-5"
                    style="width: 100%;"
                  >
                    <v-progress-linear
                      :color="passwordStrength.barColor"
                      :value="passwordStrength.value"
                      height="25"
                      striped
                    >
                      <template>
                        <strong :class="passwordStrength.textColor">{{passwordStrength.message}}</strong>
                      </template>
                    </v-progress-linear>
                  </v-row>

                  <!-- Rules indication -->
                  <v-row
                    v-if="this.password1 && this.password1.length > 0" 
                    class="mt-4 mx-2"
                  >
                    <v-stepper
                      vertical
                      class="pa-0"
                    >
                      <v-stepper-step
                        :complete="passwordStrength.rules.uppercase"
                        step=""
                      >
                        Minimum 1 uppercase character
                      </v-stepper-step>

                      <v-stepper-step
                        :complete="passwordStrength.rules.lowercase"
                        step=""
                      >
                        Minimum 1 lowercase character
                      </v-stepper-step>

                      <v-stepper-step
                        :complete="passwordStrength.rules.specialChars"
                        step=""
                      >
                        Minimum 1 special character
                      </v-stepper-step>

                      <v-stepper-step
                        :complete="passwordStrength.rules.number"
                        step=""
                      >
                        Minimum 1 digit
                      </v-stepper-step>

                      <v-stepper-step
                        :complete="passwordStrength.rules.length"
                        step=""
                      >
                        Minimum length of 10 characters
                      </v-stepper-step>

                      <v-stepper-step
                        :complete="password1 === password2 && passwordStrength.value >= 60"
                        step=""
                      >
                        Confirm your password
                      </v-stepper-step>

                    </v-stepper>
                  </v-row>

                  <!-- Update Password Button -->
                  <v-row 
                    justify="center"
                    class="mt-7 pb-4"
                  >
                    <v-btn 
                      :disabled="(passwordStrength.value >= 60 && password1 === password2 && privacyPolicy) ? false : true"
                      depressed
                      color="primary" 
                      @click="resetPassword"
                    >Update Password</v-btn>
                  </v-row>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// API
import AuthenticationController from "@/services/controllers/Authentication";
import UserController from '@/services/controllers/User'

export default {
  data: () => ({
    password1: null,
    password2: null,
    resetOutcome: null,
    resetError: false,
    minimumPercent: 60,
    numOfRules: 5,
    privacyPolicy: false,
  }),

  computed: {
    passwordStrength() {
      // Default
      let object = {
        value: 0,
        barColor: 'red',
        textColor: 'white--text',
        message: 'Not Strong Enough',
        rules: {
          lowercase: false,
          uppercase: false,
          number: false,
          specialChars: false,
          length: false
        }
      }

      // Regex
      const lowercase = new RegExp(/^(?=.*[a-z]).{1,}$/)
      const uppercase = new RegExp(/^(?=.*[A-Z]).{1,}$/)
      const number = new RegExp(/\d/)
      const specialChars = new RegExp(/^(?=.*[|=\\`¬£""#'*.!@$%^&(){}\[\]:;<>,.?\/~_+-]).{1,}$/)

      // Does it contain: 1 lowercase
      object.value += this.regexCheck(lowercase).score
      object.rules.lowercase = this.regexCheck(lowercase).result

      // Does it contain: 1 uppercase
      object.value += this.regexCheck(uppercase).score
      object.rules.uppercase = this.regexCheck(uppercase).result

      // Does it contain: 1 number
      object.value += this.regexCheck(number).score
      object.rules.number = this.regexCheck(number).result

      // Does it contain: 1 special char
      object.value += this.regexCheck(specialChars).score
      object.rules.specialChars = this.regexCheck(specialChars).result

      // Is it long enough
      object.value += this.lengthCheck().score
      object.rules.length = this.lengthCheck().result
      
      // If it matches the minimum requirements
      if(object.value >= this.minimumPercent)
        object.value += this.passwordStrengthChecker()

      // Colour & message
      if(object.value < this.minimumPercent) {
      }
      else if(object.value === this.minimumPercent) {
        object.barColor = 'orange'
        object.textColor = 'white--text'
        object.message = 'Matches minimum requirements'
      }
      else if(object.value <= 75) {
        object.barColor = 'green darken-2'
        object.textColor = 'black--text'
        object.message = 'Good password'
      }
      else if(object.value <= 85) {
        object.barColor = 'green darken-1'
        object.textColor = 'black--text'
        object.message = 'Strong password'
      }
      else if(object.value <= 100) {
        object.barColor = 'green'
        object.textColor = 'black--text'
        object.message = 'Very strong password'
      }

      return object
    }
  },

  methods: {
    goToPrivacyPolicy() {
      let route = this.$router.resolve({path: '/privacy-policy'});
      window.open(route.href, '_blank');
    },
    resetPassword() {
      this.resetOutcome = null;
      this.resetError = false;
      if(this.password1 === null || this.password2 === null ){
        this.resetOutcome =
          "Password cannot be empty.";
        this.resetError = true;          
      }
      if (this.password1 === this.password2 && this.privacyPolicy === true) {

        // Log Privacy Policy Acceptance

        // Get date and time (yyyy-MM-dd HH:mm:ss)
        let currDateTime = new Date().toISOString().slice(0, 19).replace('T', ' ');

        // Password Reset
        AuthenticationController.passwordReset({
          username: this.$route.query.email,
          password: this.password1,
          resetToken: this.$route.query.token,
          type: this.$route.query.type,
          policyAcceptanceDate: currDateTime+''
        })
        .then((res) => {
          this.$router.push({
            name: "Login",
            query: {
              redirectMsg: "Password reset/setup successfully you may now login.",
            },
          });
          this.resetError = false;
        })
        .catch((err) => {
          this.resetOutcome = ''+err.response.data.message;
          this.resetError = true;
        });
        
      } else {
        this.resetOutcome =
          "Passwords do not match, please check and try again.";
        this.resetError = true;
      }
    },
    regexCheck(statement) {
      if(statement.test(this.password1)) {
        return { 
          score: this.minimumPercent / this.numOfRules,
          result: true
        }
      }
      return { 
        score: 0,
        result: false
      }
    },
    lengthCheck() {
      if(this.password1 && this.password1.length >= 10) {
        return { 
          score: this.minimumPercent / this.numOfRules,
          result: true
        }
      }
      return { 
        score: 0,
        result: false
      }
    },
    passwordStrengthChecker() {
      let score = 0
      const target = 100 - this.minimumPercent

      // Length Mod
      score += (this.password1.length - 10) * 5

      //  Don't allow more than the allocated amount left
      if(score > target)
        score = target

      return score
    }
  },
};
</script>

<style lang="scss">
  .theme--dark.v-stepper {
    background: #1E1E1E;
  }

  .v-stepper {
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
</style>